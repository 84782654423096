
import { defineComponent, onMounted } from "vue";
import OrderDetails from "../components/OrderDetails.vue";
import {setPageHeader} from "../../../core/helpers/toolbar";

export default defineComponent({
  components: {
    OrderDetails,
  },
  setup() {
    onMounted(() => {
        setPageHeader({
          title: "message.ORDER_DETAILS",
          actionButton: {
            ability: "",
            pageAction: {
              action: "",
            },
            button: {},
          },
          breadCrumbs: [
            { name: "message.GO_BACK", link: "-1" },
            { name: "message.ORDER_DETAILS" },
          ],
        });
      })
  }
});
