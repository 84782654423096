import { gql } from 'graphql-tag';
import { GET_ORDERS, PAGINATION, ORDER_DETAIL } from './Fragments';

export const GET_ORDER_LISTS = gql`
    query GetOrders($page: Int, $limit: Int, $showDeleted: Boolean) {
        orders(limit:$limit, page:$page, showDeleted:$showDeleted){
            ...Pagination
            data {
                ...OrderFields
            }
        }
    }
    ${GET_ORDERS},
    ${PAGINATION},
`;

export const GET_LATEST_ORDER_LISTS = gql`
    query GetOrders($page: Int, $limit: Int) {
        orders_latest(limit:$limit, page:$page){
            ...OrderFields
        }
    }
    ${GET_ORDERS},
`;

export const SEARCH_ORDER_LISTS = gql`
    query SearchOrders($page: Int, $limit: Int,$filter: String, $search_key:String, $locale:String, $types:String , $date_from:String , $date_to:String , $showDeleted: Boolean , , $col: String, $type: String) {
        search_orders(limit:$limit, page:$page, filter:$filter, search_key:$search_key,locale:$locale, types:$types , date_from:$date_from , date_to:$date_to , showDeleted:$showDeleted,col:$col, type:$type){
            ...Pagination
            data {
                ...OrderFields
            }
        }
    }
    ${GET_ORDERS},
    ${PAGINATION},
`;

export const GET_ORDER_DASHBOARD = gql`
    query GetOrderDashboard($filter: String, $search_key: String, $locale: String, $type: String, $date_from: String, $date_to: String, $showDeleted: Boolean) {
        orders_dashboard(filter: $filter, search_key: $search_key, locale: $locale, type: $type, date_from: $date_from, date_to: $date_to, showDeleted: $showDeleted)
    }
`;

export const GET_ORDER_STATUS = gql`
    query GetOrderStatus {
        orders_status
    }
`;

export const GET_ORDER_DETAIL = gql`
    query GetOrderDetail($uuid: String) {
        order_detail(uuid:$uuid){
            ...OrderDetailFields
        }
    }
    ${ORDER_DETAIL},
`;

export const RESEND_EMAIL = gql`
    query ResendEmail($order_id: Int) {
        resend_email(order_id:$order_id)
    }
`;

export default { GET_ORDER_LISTS, GET_LATEST_ORDER_LISTS, GET_ORDER_DASHBOARD, GET_ORDER_DETAIL, SEARCH_ORDER_LISTS, RESEND_EMAIL };
