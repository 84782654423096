
import { defineComponent, onMounted, ref, watchEffect, computed } from 'vue';
import { Apollo, Notify } from '@/core/services';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { GET_ORDER_DETAIL, RESEND_EMAIL, GET_ORDER_STATUS } from '../graphql/Queries';
import { UPDATE_ORDER_STATUS } from '../graphql/Mutations';
import { useRoute, useRouter } from 'vue-router';
import { setPageHistory } from '@/core/helpers/toolbar';
import Table from '../../../components/Table/Table.vue';
import { generateAvatar } from '@/core/helpers/generateAvatar';
import InnerLoader from '../../../components/InnerLoader.vue';
import { Select } from '@/components/input-elements';
import { asideTheme } from '@/core/helpers/config';
import Axios from "@/core/services/Http";
export default defineComponent({
    name: 'order',
    components: { Table, InnerLoader, Select},
        
    setup() {
        const route = useRoute();
        const router = useRouter();
        const i18n = useI18n();
        const store = useStore();
        const loader = ref(false);
        const orders = ref([]) as Record<any, any>;
        const customer = ref({}) as Record<any, any>;
        const orderTotals = ref({}) as Record<any, any>;
        const system_locale = ref();
        const sendMailLoader = ref(false);
        const order_status: any = ref([]);
        const statusList = ref([]) as Record<any, any>;
        const orderStatusList = ref([]) as Record<any, any>;

        const columns = ref([
            {
                label: '',
                key: 'image'
            },
            {
                label: 'message.PRODUCT',
                key: 'description'
            },
            {
                label: 'message.QUANTITY',
                key: 'quantity'
            },
            {
                label: 'message.PRICE',
                key: 'price',
                textAlignment: 'end'
            },
            {
                label: 'message.TOTAL',
                key: 'total',
                textAlignment: 'end'
            }
        ]);

        watchEffect(() => {
            system_locale.value = i18n.fallbackLocale.value;
            statusList.value = [];
            orderStatusList.value.forEach((element, index) => {
                if(element.id == 1 || element.id == 3 || element.id == 5 || element.id == 6 || element.id == 10) {
                    statusList.value.push({
                        label: JSON.parse(element?.status)[system_locale.value]?.name,
                        value: element.id
                    });
                }
            });
        });

        const me = computed(() => {
            return store.getters.getUser;
        });

        const companyLogo = name => {
            return generateAvatar(name);
        };

        const updateStatus = async () => {
            loader.value = true;
            await Apollo.mutate({
                mutation: UPDATE_ORDER_STATUS,
                variables: { id: orders.value.id, order_status_id: order_status.value },
                update: (store, { data: { update_order_status } }) => {
                    loader.value = false;
                    Notify.success(`${i18n.t('message.RECORD_UPDATED_SUCCESSFULLY')}`);
                }
            }).catch(() => {
                loader.value = false;
            });
        };

        const orderDetails = uuid => {
            loader.value = true;
            Apollo.watchQuery({
                query: GET_ORDER_DETAIL,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    uuid: uuid
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                orders.value = data.order_detail;
                customer.value = data?.order_detail?.customer;
                orderTotals.value = {};
                if (orders.value.order_total.length > 0) {
                    orders.value.order_total.forEach(element => {
                        orderTotals.value[element.code] = {
                            title: element.title,
                            value: element.value
                        };
                    });
                }
                loader.value = false;
            });
        };

        const orderStatus = () => {
            // loader.value = true;
            Apollo.watchQuery({
                query: GET_ORDER_STATUS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only'
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                if (data?.orders_status.length > 0) {
                    orderStatusList.value = JSON.parse(data?.orders_status);
                }

                // loader.value = false;
            });
        };

        const resendMsg = order_id => {
            try {
                sendMailLoader.value = true;
                Apollo.watchQuery({
                    query: RESEND_EMAIL,
                    errorPolicy: 'all',
                    fetchPolicy: 'network-only',
                    nextFetchPolicy: 'cache-only',
                    variables: {
                        order_id: order_id
                    }
                }).subscribe(({ errors }) => {
                    if (errors) {
                        sendMailLoader.value = false;
                    }
                    sendMailLoader.value = false;
                    Notify.success(`${i18n.t('message.MESSAGE_SENT')}`);
                });
            } catch (e) {
                sendMailLoader.value = false;
                Notify.error(i18n.t('message.SOMETHING_WENT_WRONG'));
            }
        };

        const resendOrderEconomic = (order_id) => {
            loader.value = true;
            try {
               Axios.post("/economic/orders/" + order_id);
               Notify.success(i18n.t("message.ECONOMIC_ORDER_RESEND_SUCCESSFULLY"));
               router.push({ path : '/orders'})
               loader.value = false;
            } catch ($e) {
                Notify.error($e);
                loader.value = false;
            }
    
		};

        

        const clearPageHistory = () => {
            setPageHistory({});
        };
        onMounted(() => {
            orderDetails(route.params.uuid);
            orderStatus();
        });

        return {
            orders,
            me,
            customer,
            orderTotals,
            system_locale,
            columns,
            loader,
            clearPageHistory,
            companyLogo,
            i18n,
            asideTheme,
            resendMsg,
            resendOrderEconomic,
            sendMailLoader,
            updateStatus,
            statusList,
            order_status
        };
    }
});
