import { gql } from "graphql-tag";

export const DELETE_ORDER = gql`
	mutation DeleteOrder($id: Int!) {
		delete_order(id: $id)
	}
`;

export const UPDATE_ORDER_STATUS = gql`
	mutation UpdateOrderStatus($id: Int, $order_status_id: Int) {
		update_order_status(id: $id, order_status_id: $order_status_id)
	}
`;

export default { DELETE_ORDER, UPDATE_ORDER_STATUS };
