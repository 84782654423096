
export const generateAvatar = (name:string) => {
    const colours = [
        "#C71585",
        "#8FBC8F",
        "#66CDAA",
        "#006400",
        "#F08080",
        "#E6B333",
        "#20B2AA",
        "#999966",
        "#008000",
        "#FA8072",
        "#80B300",
        "#008B8B",
        "#E6B3B3",
        "#228B22",
        "#FFA07A",
        "#FF99E6",
        "#4682B4",
        "#FF1A66",
        "#2E8B57",
        "#4B0082",
        "#66994D",
        "#191970",
        "#4D8000",
        "#3CB371",
        "#663399",
        "#66664D",
        "#BC8F8F",
        "#E666FF",
        "#32CD32",
        "#483D8B",
        "#E666B3",
        "#33991A",
        "#CC9999",
        "#BDB76B",
        "#6A5ACD",
        "#4D8066",
        "#809980",
        "#E6FF80",
        "#B22222",
        "#800080",
        "#FF3380",
        "#CCCC00",
        "#66E64D",
        "#DC143C",
        "#9932CC",
        "#E64D66",
        "#4DB380",
        "#FF4D4D",
        "#CD5C5C",
        "#BA55D3",
    ];

    if (name != undefined) {
        const nameSplit = name.split(" ");
        let initials = "";
        if (nameSplit.length > 1) {
            initials =
                nameSplit[0].charAt(0).toUpperCase() +
                nameSplit[1].charAt(0).toUpperCase();
        } else {
            initials =
                name.charAt(0).toUpperCase() + name.charAt(1).toUpperCase();
        }
        const charIndex = initials.charCodeAt(0) - 65;
        let colourIndex = charIndex % 19;

        if(colourIndex < 0 ){
            colourIndex = 0;
        }

        return {
            name: initials,
            color: colours[(colourIndex + name.length)%50],
        };
    }
};
